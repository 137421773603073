.createEntry {
    width: 30rem;
    background-color: rgb(32, 32, 32);
    border-radius: 2rem;
    text-align: center;
    padding: 1rem;
    box-shadow: 0 0 10px black;
}

.form {
}
.dateTimeInput {
    background-color: grey;
    border-radius: 1rem;
    border: none;
    outline: none;
    color: white;
    padding: .5rem;
}

.checkbox {
    transform: scale(1.3);
}
br {
    display: block;
    height: 0;
}
.select {
    width: 10rem;
    background-color: grey;
    border-radius: 1rem;
    border: none;
    outline: none;
    color: white;
    padding: .5rem;
    margin: 0 auto;
}

.label {
    font-size: 1.3em;
    display: block;
    margin: .4rem;
}

.checkboxLabel {
    width: 10rem;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}


.textInput {
    width: 10rem;
    background-color: grey;
    border-radius: 1rem;
    border: none;
    outline: none;
    color: white;
    padding: .5rem;
}

.submit {
    width: 10rem;
    background-image: linear-gradient(45deg,#FC466B,#3F5EFB);
    filter: grayscale(.5);
    border-radius: 1rem;
    border: none;
    outline: none;
    color: white;
    padding: .5rem;
    margin: 0 auto;
    margin-top: 1rem;
    transition: all .3s;
}

.submit:hover {
    background-color: rgb(32, 32, 32);
    filter: grayscale(.1);
    cursor: pointer;
}
