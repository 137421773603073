.addButton {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    background-image: linear-gradient(45deg,#FC466B,#3F5EFB);
    width: 4rem;
    height: 4rem;
    border-radius: 500000px;
    text-align: center;
    filter: grayscale(.5);
    transition: all .3s;
    cursor: pointer;
    color: #d0d0d0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    z-index: 3;
}

.addButton:hover {
    filter: grayscale(0);
}

.plus {
    display: flex;
    align-items: center;
    font-size: 2.5em;
    height: 1.5em;
}
