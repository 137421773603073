.navbar {
    width: 100%;
    height: 3.5rem;
    background-color: rgb(32, 32, 32);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    display: flex;
    justify-content: space-between;
}

.belowNavbar {
    transition: all .4s;
    justify-content: center;
    gap: 6rem;
    top: 0rem;
    z-index: 2;
    overflow-x: auto;
    overflow-y: hidden;
}

@media (max-width: 800px) {
    .belowNavbar {
        gap: 3rem;
        top: 3.5rem;
    }
}

@media (max-width: 600px) {
    .belowNavbar {
        gap: 1rem;
        justify-content: start;
        padding-left: 1rem;
    }
}
.navbar:hover + .belowNavbar {
    top: 3.5rem;
}

.belowNavbar:hover {
    top: 3.5rem;
}

.navbarWrapper:hover .title {
    animation: title 2s linear infinite;
}

.navbarWrapper:hover  .logoutSvg {
    fill: #FC466B;
}

.lin_grad_back {
    color: transparent;
    -webkit-background-clip: text;
    background-image: linear-gradient(45deg,#FC466B,#3F5EFB,#FC466B);
    background-repeat: repeat-x;
    background-size: 15rem;
}
.title {
    display: block;
    font-size: 1.7em;
    font-weight: bold;
    padding-top: 0.5rem;
    transition: transform 1s;
    width: 10rem;
    text-align: center;
}

.titleDev {
    width: 20rem !important;
}

.logoutSvg {
    fill: #3F5EFB;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.5rem;
    cursor: pointer;
    transition: fill 1s;
}

.logoutButton {
    background-color: transparent;
    border: none;
}

.flexSide {
    width: 3rem;
    display: flex;
    flex-shrink: 0;
}

.logoutButtonWrapper {
    display: flex;
    align-items: center;
    padding-right: 3rem;
}

@media (max-width: 600px) {
    .logoutButtonWrapper {
        padding: 0;
    }
}

.loadingBar {
    background-image: linear-gradient(45deg,#FC466B,#3F5EFB,#FC466B);
    background-repeat: repeat-x;
    position: absolute;
    top: 3.5rem;
    height: .6rem;
    width: 100%;
    transition: all 2s;
    z-index: 2;
    animation: loadingBar 2s linear infinite;
    display: none;
}

.navbarWrapper:hover .loadingBar{
    top: 7rem;
}

.active {
    display: block;
}

@keyframes loadingBar {
    0% {
        background-position-x: 0;
    }
    100% {
        background-position-x: 230rem;
    }
}

@keyframes title{
    0% {
        background-position-x: 0rem;
    }
    100% {
        background-position-x: 15rem;
    }
}
