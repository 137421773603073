.navTarget {
    height: 2.5rem;
    width: fit-content;
    text-align: center;
    background-color: #2e2e2e;
    font-size: 1.5em;
    transition: all .3s;
    padding: .2rem .8rem;
    border-radius: .5rem;
    color: white;
}
.navTarget:hover {
    background-color: #585858;
    cursor: pointer;
    border-radius: .2rem;
}

.active {
}

.underline {
    height: 1px;
    background-color: #d0d0d0;
    width: 0%;
    transition: all .3s;
}

.navTarget:hover .underline {
    width: 100%;
    transition: all .3s;
}
