.dateTimeInput {
    background-color: grey;
    border-radius: 1rem;
    border: none;
    outline: none;
    color: white;
    padding: .5rem;
}
.form {
    display: flex;
    gap: 1rem;
    margin: 1rem;
}
.entryWrapper {
    display: flex;
    width: 100%;
    padding: 1rem;
    flex-wrap: wrap;
}