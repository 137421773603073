.entry {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #525252;
    padding: 1rem;
    padding-bottom: 0;
    border-radius: 5px;
    transition: all 0.4s ease-in-out;
    width: 20rem;
    height: fit-content;
    margin: .3rem;
}

.entry:hover {
    background-color: #565656;
    transform: scale(1.05);
    box-shadow: 0 0 0.5rem 0.03rem rgba(155, 155, 155, 0.3);
    position: relative;
}

.title {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 1.5em;
}
.from {

}
.to {

}

.img {
    width: 1.5rem;
    height: 1.5rem;
}

.filter {
    filter: invert(1);
}

.placeholder {
    width: 1.5rem;
    height: 1rem;
}
.deleteSvg {
    transition: all 0.4s ease-in-out;
    width: 1rem;
    height: 1rem;
    fill: transparent;
}
.copySvg{
    margin: 0 auto;
    fill: transparent;
    transition: all 0.4s ease-in-out;
    width: .9rem;
    height: .9rem;
}
.button {
    background-color: #616161;
    max-height: 2rem;
    padding: 0.5rem;
    border: none;
    border-radius: 20px;
    transition: transform 0.4s ease-in-out, order-radius 0.4s ease-out, background-color 0.4s ease-out, color 0.4s ease-out;
    cursor: pointer;
    transform: scale(.4);
    text-align: center;
}

.entry:hover .deleteSvg {
    fill: #b30000;
    border-radius: 0.2rem;
}

.entry:hover .copySvg {
    fill: #ffffff;
    border-radius: 0.2rem;
}

.entry:hover .button {
    border-radius: 0.5rem;
    background-color: #6a6a6a;
    transform: scale(1);
    transition: all 0.4s ease-in-out;
}

.divider {
    transition: all 0.4s ease-in-out;
    background-color: transparent;
    margin: 0;
    align-self: start;
    height: 2px;
    width: 0%;
}

.entry:hover .divider {
    width: 100%;
    height: 1px;
    background-color: #727272;
    margin: .7rem 0;
}

.actions {
    display: flex;
    gap: 1rem;
    height: 2rem;
    transition: height 0.4s ease-in-out;
}

.entry:hover .actions {
    height: 2.5rem;
}
