.modalContainer {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 5;
}

.active {
    background-color: rgba(71,71,71,0.57);
}
