* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.fc-event:hover {
  cursor: pointer;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(53, 53, 53);
  color: white;
  font-size: 16px;
}
.fc-list-day-text {
  color: black !important;
}
.fc-daygrid-week-number {
  color: white !important;
}
